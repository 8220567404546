import type { TMarket } from 'markets-store/types'
import { PERIOD_SPECIFIERS } from 'markets-store/constants'

export interface NameTmp {
  main: string
  time: string
}

export type Specifier = (typeof PERIOD_SPECIFIERS)[number]
export interface PeriodSpecifiers {
  id: Specifier
  text: string
}

export const PERIOD_VARIABLES_NAMES = new Set(PERIOD_SPECIFIERS)

export function useLocalizeFastBets(market?: Ref<TMarket>) {
  const i18n = useI18n()
  const rowName = ref<NameTmp>({ main: '', time: '' })
  const marketHeaderName = computed(
    () => rowName.value?.main || market?.value?.name || '',
  )

  const fastBetSpecifiersMap = computed<Record<number, PeriodSpecifiers>>(
    () => ({
      6: {
        id: 'quarternr',
        text: i18n.t('specifiers.quarternr'),
      },
      7: {
        id: 'setnr',
        text: i18n.t('specifiers.setnr'),
      },
      9: {
        id: 'inning',
        text: i18n.t('specifiers.inning'),
      },
      10: {
        id: 'quarternr',
        text: i18n.t('specifiers.quarternr'),
      },
      16: {
        id: 'setnr',
        text: i18n.t('specifiers.setnr'),
      },
      20: {
        id: 'setnr',
        text: i18n.t('specifiers.setnr'),
      },
      25: {
        id: 'inning',
        text: i18n.t('specifiers.inning'),
      },
      33: {
        id: 'setnr',
        text: i18n.t('specifiers.setnr'),
      },
      83: {
        id: 'half',
        text: i18n.t('specifiers.half'),
      },
      88: {
        id: 'quarternr',
        text: i18n.t('specifiers.quarternr'),
      },
      92: {
        id: 'half',
        text: i18n.t('specifiers.half'),
      },
      99: {
        id: 'half',
        text: i18n.t('specifiers.half'),
      },
      102: {
        id: 'periodnr',
        text: i18n.t('specifiers.periodnr'),
      },
      135: {
        id: 'half',
        text: i18n.t('specifiers.half'),
      },
      145: {
        id: 'frame',
        text: i18n.t('specifiers.frame'),
      },
      149: {
        id: 'half',
        text: i18n.t('specifiers.half'),
      },
      157: {
        id: 'setnr',
        text: i18n.t('specifiers.setnr'),
      },
      165: {
        id: 'setnr',
        text: i18n.t('specifiers.setnr'),
      },
      167: {
        id: 'setnr',
        text: i18n.t('specifiers.setnr'),
      },
      172: {
        id: 'setnr',
        text: i18n.t('specifiers.setnr'),
      },
      100248: {
        id: 'map',
        text: i18n.t('specifiers.map'),
      },
    }),
  )
  const fastBetRatesMap = computed<
    Record<number, { main: string; time: string }>
  >(() => ({
    1: {
      main: i18n.t('fastBets.exodus1x2'),
      time: i18n.t('fastBets.mainTime'),
    },
    340: {
      main: i18n.t('fastBets.winner'),
      time: i18n.t('fastBets.inclSuperover'),
    },
    186: {
      main: i18n.t('fastBets.winner'),
      time: i18n.t('fastBets.match'),
    },
    251: {
      main: i18n.t('fastBets.winner'),
      time: i18n.t('fastBets.withOTInning'),
    },
    256: {
      main: i18n.t('fastBets.hcp'),
      time: i18n.t('fastBets.withOTInning'),
    },
    258: {
      main: i18n.t('fastBets.total'),
      time: i18n.t('fastBets.withOTInning'),
    },
    188: {
      main: i18n.t('fastBets.hcp'),
      time: i18n.t('fastBets.bySets'),
    },
    314: {
      main: i18n.t('fastBets.total'),
      time: i18n.t('fastBets.sets'),
    },
    493: {
      main: i18n.t('fastBets.hcp'),
      time: i18n.t('fastBets.byFrames'),
    },
    237: {
      main: i18n.t('fastBets.hcp'),
      time: i18n.t('fastBets.match'),
    },
    238: {
      main: i18n.t('fastBets.total'),
      time: i18n.t('fastBets.match'),
    },
    494: {
      main: i18n.t('fastBets.total'),
      time: i18n.t('fastBets.frames'),
    },
    187: {
      main: i18n.t('fastBets.hcp'),
      time: i18n.t('fastBets.byGames'),
    },
    315: {
      main: i18n.t('fastBets.exodus1x2'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')}`,
    },

    375: {
      main: i18n.t('fastBets.winner'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')} {legnr} ${i18n.t(
        'fastBets.leg',
      )}`,
    },
    210: {
      main: i18n.t('fastBets.winner'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')} {gamenr} ${i18n.t(
        'specifiers.gamenr',
      )}`,
    },
    309: {
      main: i18n.t('fastBets.hcp'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')}`,
    },
    371: {
      main: i18n.t('fastBets.hcp'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')}`,
    },
    372: {
      main: i18n.t('fastBets.total'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')}`,
    },
    310: {
      main: i18n.t('fastBets.total'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')}`,
    },
    189: {
      main: i18n.t('fastBets.total'),
      time: i18n.t('fastBets.games'),
    },
    219: {
      main: i18n.t('fastBets.winner'),
      time: i18n.t('fastBets.inclOt'),
    },
    406: {
      main: i18n.t('fastBets.winner'),
      time: i18n.t('fastBets.withOTandPenalties'),
    },
    202: {
      main: i18n.t('fastBets.winner'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')}`,
    },
    245: {
      main: i18n.t('fastBets.winner'),
      time: `{!gamenr} ${i18n.t('fastBets.ruSetenGame')}`,
    },
    287: {
      main: i18n.t('fastBets.exodus1x2'),
      time: `{!inningnr} ${i18n.t('specifiers.inning')}`,
    },
    235: {
      main: i18n.t('fastBets.exodus1x2'),
      time: `{!quarternr} ${i18n.t('fastBets.quarter')}`,
    },
    60: {
      main: i18n.t('fastBets.exodus1x2'),
      time: `1 ${i18n.t('specifiers.half')}`,
    },
    83: {
      main: i18n.t('fastBets.exodus1x2'),
      time: `2 ${i18n.t('specifiers.half')}`,
    },
    443: {
      main: i18n.t('fastBets.exodus1x2'),
      time: `{!periodnr} ${i18n.t('fastBets.period')}`,
    },
    16: {
      main: i18n.t('fastBets.hcp'),
      time: i18n.t('fastBets.mainTime'),
    },
    223: {
      main: i18n.t('fastBets.hcp'),
      time: i18n.t('fastBets.inclOt'),
    },
    527: {
      main: i18n.t('fastBets.hcp'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')}`,
    },
    528: {
      main: i18n.t('fastBets.total'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')}`,
    },
    246: {
      main: i18n.t('fastBets.hcp'),
      time: `{!gamenr} ${i18n.t('fastBets.ruSetenGame')}`,
    },
    746: {
      main: i18n.t('fastBets.hcp'),
      time: `{!inningnr} ${i18n.t('specifiers.inning')}`,
    },
    303: {
      main: i18n.t('fastBets.hcp'),
      time: `{!quarternr} ${i18n.t('fastBets.quarter')}`,
    },
    203: {
      main: i18n.t('fastBets.hcp'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')}`,
    },
    66: {
      main: i18n.t('fastBets.hcp'),
      time: `1 ${i18n.t('specifiers.half')}`,
    },
    88: {
      main: i18n.t('fastBets.hcp'),
      time: `2 ${i18n.t('specifiers.half')}`,
    },
    460: {
      main: i18n.t('fastBets.hcp'),
      time: `{!periodnr} ${i18n.t('fastBets.period')}`,
    },
    18: {
      main: i18n.t('fastBets.total'),
      time: i18n.t('fastBets.mainTime'),
    },
    225: {
      main: i18n.t('fastBets.total'),
      time: i18n.t('fastBets.inclOt'),
    },
    204: {
      main: i18n.t('fastBets.total'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')}`,
    },
    247: {
      main: i18n.t('fastBets.total'),
      time: `{!gamenr} ${i18n.t('fastBets.ruSetenGame')}`,
    },
    288: {
      main: i18n.t('fastBets.total'),
      time: `{!inningnr} ${i18n.t('specifiers.inning')}`,
    },
    605: {
      main: i18n.t('fastBets.total'),
      time: `{!inningnr} ${i18n.t('specifiers.inning')}`,
    },
    236: {
      main: i18n.t('fastBets.total'),
      time: `{!quarternr} ${i18n.t('fastBets.quarter')}`,
    },
    68: {
      main: i18n.t('fastBets.total'),
      time: `1 ${i18n.t('specifiers.half')}`,
    },
    90: {
      main: i18n.t('fastBets.total'),
      time: `2 ${i18n.t('specifiers.half')}`,
    },
    446: {
      main: i18n.t('fastBets.total'),
      time: `{!periodnr} ${i18n.t('fastBets.period')}`,
    },
    29: {
      main: i18n.t('fastBets.bothTeam'),
      time: i18n.t('fastBets.mainTime'),
    },
    520: {
      main: i18n.t('fastBets.winner'),
      time: `{!gamenr} ${i18n.t('fastBets.ruSetenGame')} {pointnr} ${i18n.t(
        'specifiers.pointnr',
      )}`,
    },
    499: {
      main: i18n.t('fastBets.winner'),
      time: `{!framenr} ${i18n.t('specifiers.frame')}`,
    },
    500: {
      main: i18n.t('fastBets.hcp'),
      time: `{!framenr} ${i18n.t('specifiers.frame')}`,
    },
    501: {
      main: i18n.t('fastBets.total'),
      time: `{!framenr} ${i18n.t('specifiers.frame')}`,
    },
    62: {
      main: i18n.t('fastBets.nextGoal'),
      time: `1 ${i18n.t('specifiers.half')}`,
    },
    302: {
      main: i18n.t('fastBets.zeroHcp'),
      time: `{!quarternr} ${i18n.t('fastBets.quarter')}`,
    },
    444: {
      main: i18n.t('fastBets.nextGoal'),
      time: `{!periodnr} ${i18n.t('fastBets.period')}`,
    },
    8: {
      main: i18n.t('fastBets.nextGoal'),
      time: i18n.t('fastBets.mainTime'),
    },
    903: {
      main: i18n.t('fastBets.nextGoal'),
      time: i18n.t('fastBets.inclOt'),
    },
    322: {
      main: i18n.t('fastBets.winner'),
      time: `{!setnr} ${i18n.t('specifiers.setnr')} {endnr} ${i18n.t(
        'fastBets.end',
      )}`,
    },
    366: {
      main: i18n.t('fastBets.hcp'),
      time: i18n.t('fastBets.byLegs'),
    },
    367: {
      main: i18n.t('fastBets.total'),
      time: i18n.t('fastBets.legs'),
    },
    330: {
      main: i18n.t('fastBets.winner'),
      time: `{!mapnr} ${i18n.t('specifiers.mapnr')}`,
    },
    331: {
      main: i18n.t('fastBets.hcp'),
      time: `{!mapnr} ${i18n.t('specifiers.mapnr')}`,
    },
    332: {
      main: i18n.t('fastBets.total'),
      time: `{!mapnr} ${i18n.t('specifiers.mapnr')}`,
    },
    452: {
      main: i18n.t('fastBets.bothTeam'),
      time: `{!periodnr} ${i18n.t('fastBets.period')}`,
    },
    75: {
      main: i18n.t('fastBets.bothTeam'),
      time: `1 ${i18n.t('specifiers.half')}`,
    },
    95: {
      main: i18n.t('fastBets.bothTeam'),
      time: `2 ${i18n.t('specifiers.half')}`,
    },
  }))

  function getFastBetRateNameByMarketId(marketId: number) {
    return fastBetRatesMap.value[marketId] || ''
  }

  function getPeriodNameBySportId(sportId: number) {
    return fastBetSpecifiersMap.value[sportId]?.text || ''
  }

  const fastBetName = computed(() =>
    getFastBetRateNameByMarketId(market?.value?.id ?? 0),
  )

  watch(
    () => `${market?.value.id}-${market?.value.specifiersString}`,
    () => {
      if (!market?.value) return
      let nameTmp: NameTmp = { main: '', time: '' }
      rowName.value = { ...nameTmp }

      if (fastBetName.value) {
        nameTmp = { ...fastBetName.value }
        let matches = nameTmp.time.toString().match(/\{!(\w+)\}/)
        if (matches && matches[0] && matches[1]) {
          nameTmp.time = nameTmp.time
            .toString()
            .replace(matches[0], market.value.specifiers[matches[1]] as string)
        }

        matches = nameTmp.time.toString().match(/\{(\w+)\}/)
        if (matches && matches[0] && matches[1]) {
          nameTmp.time = nameTmp.time
            .toString()
            .replace(matches[0], market.value.specifiers[matches[1]] as string)
        }
        rowName.value = { ...nameTmp }
      } else {
        rowName.value.main = market.value.name
      }
    },
    { immediate: true },
  )

  return {
    rowName,
    marketHeaderName,
    fastBetSpecifiersMap,
    getFastBetRateNameByMarketId,
    getPeriodNameBySportId,
  }
}
