/**
 * Creates a function like `round`.
 *
 * @private
 * @param methodName The name of the `Math` method to use when rounding.
 * @returns Returns the new round function.
 */
export function _createRound(methodName: 'round' | 'floor' | 'ceil') {
  const func = Math[methodName]
  return (number: number, precision = 0) => {
    precision =
      precision == null
        ? 0
        : precision >= 0
          ? Math.min(precision, 292)
          : Math.max(precision, -292)

    if (precision) {
      // Shift with exponential notation to avoid floating-point issues.
      // See [MDN](https://mdn.io/round#Examples) for more details.
      let pair = `${number}e`.split('e')
      const value = func(Number(`${pair[0]}e${+pair[1] + precision}`))

      pair = `${value}e`.split('e')
      return Number(`${pair[0]}e${+pair[1] - precision}`)
    }

    return func(number)
  }
}
