import { _createRound } from '../_createRound'

/**
 * Computes number rounded down to precision.
 *
 * @param number the number to round down.
 * @param precision the precision to round down to.
 * @return Returns the rounded down number.
 *
 * ```ts
 * floor(5.005) -> 5
 *
 * floor(5.035, 2) -> 5.03
 *
 * floor(55, -1) -> 50
 * ```
 */
export const floor = _createRound('floor')
