import type { Ref } from 'vue'

type MaybeRef<T> = T | Ref<T>

export function useStartDate(event: MaybeRef<{ scheduled: string | number }>) {
  const { parseDate } = useDate()
  const { t } = useI18n()

  const startDate = computed(() => {
    const scheduled = parseDate(unref(event).scheduled)
    const tomorrow = parseDate().add(1, 'days')
    let date = scheduled.format('LL')

    if (scheduled.isSame(new Date(), 'day')) {
      date = t('sportEvent.today')
    } else if (scheduled.isSame(tomorrow, 'day')) {
      date = t('sportEvent.tomorrow')
    }
    return date
  })

  const startTime = computed(() =>
    parseDate(unref(event).scheduled).format('LT'),
  )

  return {
    startDate,
    startTime,
  }
}
