/* eslint camelcase: "off" */
import type { Ref } from 'vue'
import {
  SB_OVERTIME_STATUSES,
  SB_PENALTY_STATUSES_BY_SPORT,
  SPORT_IDS,
} from 'markets-store/constants'
import type { TSportEvent } from 'markets-store'
import { floor, isObject } from '@st/utils'
import type { Duration } from '@st/i18n/composables/useDate'
import { useLiveClockStore } from '../../stores/useLiveClockStore'

interface TSportEventClock {
  stopped?: boolean
  match_time?: string
  remaining_time_in_period?: string
  remaining_time?: string
  stoppage_time?: string
  stoppage_time_announced?: string
}

export interface UseLiveClockOptions {
  onlyMinutes?: boolean
  matchTimingFormat?: 'short' | 'full' | 'shortWithFallback'
}

export interface UseLiveClockReturn {
  clock: Readonly<Ref<string>>
  isShownClock: Readonly<Ref<boolean>>
  matchTiming: Readonly<Ref<string | undefined>>
}

function parseLanguageMap(
  languageMap: Record<string, string> | string,
  locale: string,
): string {
  if (typeof languageMap === 'string') return languageMap

  return languageMap[locale] ?? languageMap.en ?? Object.values(languageMap)[0]
}

export function useLiveClock(
  event: TSportEvent | Ref<TSportEvent>,
  {
    onlyMinutes = false,
    matchTimingFormat = 'short',
  }: UseLiveClockOptions = {},
): UseLiveClockReturn {
  const i18n = useI18n()

  const { duration } = useDate()

  const { currentTimestamp } = storeToRefs(useLiveClockStore())
  const baseTimestamp = currentTimestamp.value

  function parseTimer(timer: string): Duration {
    const [minutes, seconds] = timer.split(':').map(Number)
    return duration({ minutes, seconds })
  }

  function formatTimerWithSeconds(timer: Duration): string {
    return [floor(timer.asMinutes()), timer.seconds()]
      .map((v) => v?.toString()?.padStart(2, '0'))
      .join(':')
  }

  function formatTimerWithoutSeconds(timer: Duration): string {
    return `${floor(timer.asMinutes())}'`
  }

  function formatTimer(timer: Duration): string {
    return onlyMinutes
      ? formatTimerWithoutSeconds(timer)
      : formatTimerWithSeconds(timer)
  }

  function incrementTimer(
    timer: string | undefined,
    secondsToAdd: number,
    isOvertime = false,
  ) {
    if (!timer) return timer

    const parsedTimer = parseTimer(timer)

    const updatedTimer = parsedTimer.add({ seconds: secondsToAdd })

    if (updatedTimer.asMilliseconds() <= 0) {
      return formatTimerWithSeconds(duration({ minutes: 0, seconds: 0 }))
    }
    if (updatedTimer.asMinutes() >= 90 && !isOvertime) {
      return formatTimerWithSeconds(duration({ minutes: 90, seconds: 0 }))
    }

    return formatTimerWithSeconds(updatedTimer)
  }

  const sportId = computed(() => unref(event).sportId)
  const matchStatus = computed(() => unref(event)?.match?.status)
  const lastPeriodStatus = computed(
    () => unref(event)?.match?.stats?.period_scores?.at(-1)?.match_status_code,
  )
  const isOvertimeStatus = computed(() =>
    matchStatus.value
      ? (Object.values(SB_OVERTIME_STATUSES) as number[]).includes(
          matchStatus.value,
        )
      : false,
  )
  const isPenaltyStatus = computed(() =>
    matchStatus.value
      ? SB_PENALTY_STATUSES_BY_SPORT[sportId.value]?.includes(matchStatus.value)
      : false,
  )

  const matchClockAtEventTime = computed(
    () => unref(event)?.match?.stats?.clock ?? {},
  )
  const matchClockAtCurrentTime = computed<TSportEventClock>(() => {
    const {
      remaining_time_in_period,
      remaining_time,
      match_time,
      stoppage_time,
      stoppage_time_announced,
      stopped,
    } = matchClockAtEventTime.value

    if (stopped) return matchClockAtEventTime.value

    const seconds = floor(
      (currentTimestamp.value -
        Number(unref(event)?.match?.timestamp ?? baseTimestamp)) /
        1000,
    )

    return {
      stoppage_time: incrementTimer(stoppage_time, seconds),
      match_time: stoppage_time
        ? match_time
        : incrementTimer(match_time, seconds, isOvertimeStatus.value),
      remaining_time: incrementTimer(remaining_time, -seconds),
      remaining_time_in_period: incrementTimer(
        remaining_time_in_period,
        -seconds,
      ),
      stoppage_time_announced,
      stopped,
    }
  })

  const clock = computed(() => {
    const {
      remaining_time_in_period,
      remaining_time,
      match_time,
      stoppage_time,
      stoppage_time_announced,
    } = matchClockAtCurrentTime.value

    if (remaining_time_in_period)
      return formatTimer(parseTimer(remaining_time_in_period))
    if (remaining_time) return formatTimer(parseTimer(remaining_time))

    if (stoppage_time && match_time) {
      const timer = formatTimer(
        parseTimer(match_time).add(parseTimer(stoppage_time)),
      )

      if (stoppage_time_announced) {
        const announcedStoppageMinutes = parseTimer(
          stoppage_time_announced,
        ).minutes()
        return `${timer} +${announcedStoppageMinutes}`
      }
      return timer
    }
    if (stoppage_time_announced) {
      const timer = formatTimer(parseTimer(match_time ?? '00:00'))
      const announcedStoppageMinutes = parseTimer(
        stoppage_time_announced,
      ).minutes()
      return `${timer} +${announcedStoppageMinutes}`
    }
    return match_time ? formatTimer(parseTimer(match_time)) : ''
  })

  const isShownClock = computed(
    () =>
      Boolean(clock.value) &&
      !isPenaltyStatus.value &&
      (matchStatus.value === lastPeriodStatus.value ||
        sportId.value === SPORT_IDS.BASKETBALL ||
        (sportId.value === SPORT_IDS.SOCCER && isOvertimeStatus.value)),
  )

  const fullMatchTiming = computed(() => {
    const description = unref(event).matchStatusObj?.description
    return isObject(description)
      ? parseLanguageMap(description, i18n.locale.value)
      : description
  })

  function getShortMatchTiming(str?: string) {
    if (!str) return str

    if (str.includes('Перерыв')) return 'Пер.'
    if (str.includes('карта') || str.includes('сет')) return str

    const pattern = /^(\d+)\s*([a-zA-Zа-яА-Я])(.*)/

    const match = str.match(pattern)
    if (!match) return fullMatchTiming.value

    const [, number, firstLetter] = match

    if (str.includes('Овертайм')) return `${number}ОТ`

    const transformedFirstLetter = firstLetter.toUpperCase()

    return `${number}${transformedFirstLetter}`
  }

  const matchTimingShort = computed(() => {
    const str = fullMatchTiming.value

    return getShortMatchTiming(str)
  })

  const matchTimingShortWithFallback = computed(() => {
    const description = unref(event).matchStatusObj?.firstPeriodStatus

    return matchStatus.value === 0
      ? getShortMatchTiming(description)
      : matchTimingShort.value
  })

  const matchTiming = computed(() => {
    switch (matchTimingFormat) {
      case 'full':
        return fullMatchTiming.value
      case 'shortWithFallback':
        return matchTimingShortWithFallback.value
      default:
        return matchTimingShort.value
    }
  })

  return {
    clock,
    isShownClock,
    matchTiming,
  }
}
