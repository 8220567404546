/*
 НЕ ИСПОЛЬЗОВАТЬ НАПРЯМУЮ В КОМПОНЕНТАХ
 Возвращает инстанс маркетстора
 */
import type { MarketStoreInstance } from 'markets-store/factory/types'

export function useMarketsStore() {
  return (useNuxtApp().$marketsStore ??
    inject('marketsStore')) as MarketStoreInstance
}
